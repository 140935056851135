import { THEME } from '@qtc-repo/common/contexts/ThemeContext'
import Flag from '../../../../assets/images/union-flag.svg'
import Logo from 'qtc-new/components/Logo'
import Image from 'next/image'

const Header = ({ theme }: { theme: THEME }) => {
  return (
    <header className="relative z-20 bg-gradient-to-b from-gray-100 to-gray-50 dark:from-gray-900 dark:to-gray-900">
      <nav className="mx-auto max-w-screen-xl border-gray-200 px-4 py-6 md:px-20 ">
        <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between gap-2">
          <Logo dark={theme === THEME.LIGHT} />
          <div className=" flex justify-end">
            <Image className="max-h-full w-10 md:w-12" src={Flag} alt="uk logo" />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
